import styled from 'styled-components'

export const Header = styled.div`
    position: relative;
    display: flex;
    margin-top: 160px;
    height: 90px;

    justify-content: center;
    align-items: center;
`
